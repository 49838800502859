<!--
 * @Author: xr
 * @Date: 2022-03-10 09:25:57
 * @LastEditors: xr
 * @LastEditTime: 2022-03-10 16:17:52
 * @FilePath: \postStation-office\src\views\talent.vue
-->
<template >
  <div class="main_box sub-component-background-color">
    <div class="new-border">
      <div class="new-title">{{ talentData?talentData.headline:'' }}</div>
      <center>
        <span> 时间：{{talentData.createTime.substr(0,10)}} </span>
      </center>
      <article>
        <div class="content" v-html="talentData?talentData.detailedInformation:''"/>
      </article>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Talent',
  data() {
    return {
      // 人才招聘数据
      talentData: {
        createTime: ''
      }
    }
  },
  activated() {
    // 刷新
    if (JSON.stringify(this.$route.params) !== '{}') {
      this.talentData = this.$route.params
      sessionStorage.setItem('talentRecruitment', JSON.stringify(this.$route.params))
    } else {
      this.talentData = JSON.parse(sessionStorage.getItem('talentRecruitment'))
    }
  }
}
</script>
<style lang="scss" scoped>
.new-border{
  border: 1px solid #bbdbff;
  border-radius: 8px;
  font-size: 14px;
  margin: 10px auto 20px auto;
  .new-title{
    text-align: center;
    font-size: 24px;
    line-height: 30px;
    padding: 15px 50px 10px 50px;
    vertical-align: middle;
    font-family: "微软雅黑";
    color: #0967c1;
    font-weight: bold;
  }
  center{
    line-height: 30px;
    vertical-align: middle;
    color: #757575;
    font-size: 12px;
  }
  article{
    font-size: 14px;
    line-height: 25px;
    margin: 0 40px;
    border-top: 1px solid #94b6d7;
    padding: 15px 0px;
    .content{
      min-height: 50vh;
      text-indent:2em;
      ::v-deep img{
        max-width: 100%;
      }
    }
    .file{
      display: block;
      color: #0967c1;
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
</style>
